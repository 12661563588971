body {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/landing.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #0a0f24;
  }
  
  .login-container {
    background-color: rgb(243, 246, 247);
    padding: 10px;
    border-radius: 10px;
    max-width: 100%;
    width: 80%;
    margin: auto;
    position: fixed;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 0px 15px rgb(255, 255, 255);
  }

  
  .dashboard-container {
    #background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/background.jpg');
    background-color: rgba(10, 15, 36, 0.8); /* Légère transparence pour le conteneur principal du dashboard */
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    max-width: 100%;
    margin: 20px auto;
    color: #f0f0f0; /* Texte clair pour contraster avec le fond */
  }
  
  .dashboard-content {
    background-color: transparent;
  }
  
  .header-container {
    background-color: transparent;
  }
  
  .card, .dashboard-item {
    background-color: rgba(55, 63, 81, 0.8); /* Couleur adaptée avec légère transparence, plus appropriée au fond sombre */
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 30px; /* Coins arrondis */
    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.758);
    color: #f0f0f0; /* Texte clair pour contraster avec le fond */
  }
  
  @media (max-width: 600px) {
    h1, h3 {
      font-size: 1.5rem;
    }
  }